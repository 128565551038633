import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";

export default function ImageSwiperPools() {
    return (
        <>
            <Swiper
                pagination={{
                    type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                slidesPerView={"auto"}
            >

                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}height={"250px"}  alt={"Example Work"} src={"../images/pool/1.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/2.jpg"} />
                        </div>
                    </div>
                </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/3.jpg"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/4.jpg"} />
                    </div>
                </div>
            </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/5.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/6.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/7.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/8.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/9.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/10.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/11.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/12.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/13.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/14.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/15.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/16.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/17.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/18.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/19.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/21.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/22.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/23.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/24.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/25.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/26.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/27.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/28.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/pool/29.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/pool/30.jpg"} />
                        </div>
    
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
