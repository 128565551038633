import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";

export default function ImagesSwiperPavers() {
    return (
        <>
            <Swiper
                pagination={{
                    type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                slidesPerView={"auto"}
            >

                <SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/3_5gtpVtXHly.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669174052"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/4_Xz-Sd7hxF.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669174316"} />
                    </div>
                </div>
            </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/5_WlUrJvTeK.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669174468"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/6_eSi0O2CcD.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669174485"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/7_dY_8ZKsZd.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659669174688"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/8_MKFqOQeQS.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669174841"} />
                        </div>
                    </div>
                </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/9_deyG--pfj.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669175061"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/10_uiXlTvofc.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669173189"} />
                    </div>
                </div>
            </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/11_tqh2ID8RR.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659669173724"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/12_rfAu_HB6q.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659669173624"} />
                    </div>
                </div>
            </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}  alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/1a_DdPzse7Ht.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659669173564"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/pavers-concrete/new/2a_vqsnSrdiU.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659669173797"} />
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
