import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";

export default function ImagesSwiperTurf() {
    return (
        <>
            <Swiper
                pagination={{
                    type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                slidesPerView={"auto"}
            >

                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}height={"250px"}  alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/3a_qeMxUbsNw.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668682444"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/4a_D1vHfOFSP.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668682965"} />
                        </div>
                    </div>
                </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/5a_kLIuslwr0.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668683267"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/6a_IPFuC_m-vj.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668684020"} />
                    </div>
                </div>
            </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/1a_2-SV5va47.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668680510"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/2a_2l84c2uml.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668682319"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/7a_k-pxXe8XYM.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668684149"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/8a_-M7Nhfa6w.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668684372"} />
                        </div>
                    </div>
                </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/9a_leB1lv3UK.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668685036"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/10a_74HajjUdS.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668678587"} />
                    </div>
                </div>
            </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/11a_Jw5d9yBBxd.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668678714"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/12a_8-ot_x-0GS.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668679138"} />
                    </div>
                </div>
            </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/13a_6T9eCd8dV.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668679402"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/14a_M7XJpbwHa.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668679706"} />
                    </div>
                </div>
            </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/15a_neRw5n1W-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668679708"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/16a_Sbxd21qX0.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668679910"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/17_9xSEi21hb.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668680061"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/18_SZ2q1wCIA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668680326"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/19_SZRmF4SiA.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668680368"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/20_GcxNgXMzi.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668680846"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/21_Q1CS90pO0z.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681015"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/22_ZyNG0SBFT.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681149"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/23_aJSauVmEI.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681249"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/24_lJPBrfuet.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681612"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/25_1j_1QcpED.PNG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681678"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/26_tJv07cs6WB.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681841"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/27_TLzy3Mlhv.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1659668681984"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"https://ik.imagekit.io/7edcjy4g6/turf/new/28_FnuSuTRL2w.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1659668682072"} />
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}