import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";

export default function ImagesSwiperRemodel() {
    return (
        <>
            <Swiper
                pagination={{
                    type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                slidesPerView={"auto"}
            >

                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}  alt={"Example Work"} src={"../images/remodel/8.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/remodel/7.jpg"} />
                        </div>
                    </div>
                </SwiperSlide><SwiperSlide>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/remodel/6.jpg"} />
                    </div>
                    <div className={"p-2"}>
                        <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/remodel/5.jpg"} />
                    </div>
                </div>
            </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"}alt={"Example Work"} src={"../images/remodel/4.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/remodel/3.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"flex flex-col"}>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/remodel/2.jpg"} />
                        </div>
                        <div className={"p-2"}>
                            <StaticImage className={"max-h-[350px]"} alt={"Example Work"} src={"../images/remodel/1.jpg"} />
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
