import * as React from 'react'
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import ImagesSwiperTurf from "./images-swiper-turf";
import ImageSwiperPools from "./image-swiper-pools";
import ImagesSwiperPavers from "./images-swiper-pavers";
import ImagesSwiperRemodel from "./images-swiper-remodel";

export default function ServiceOnServicePage(props){
    return(
        <>
        <div className={"dark:bg-gray-900 dark:text-white transition duration-500 py-24 lg:py-56 lg:pb-20"}>
            <div id={"artificial-grass"} className={"flex flex-row flex-wrap-reverse justify-evenly lg:p-16 p-8 pb-0"}>
                <div className={"flex flex-row flex-wrap lg:w-1/2 w-full justify-evenly"}>

                    <ImagesSwiperTurf/>






                </div>
                <div className={"lg:w-1/2 w-full p-2 lg:p-4"}>
                    <div className={"max-w-lg mx-auto"}>
                    <h1 className={"text-5xl font-bold text-center pb-4"}>Artificial Grass</h1>
                    <h1 className={"text-3xl font-bold text-center pb-8 text-main-color-500"}>Top Of The Line Turf Services</h1>

                    <p className={"p-2 lg:p-4 leading-loose"}>Do away with the maintenance hassle and wasted water of natural grass and upgrade to artificial grass to keep your outdoor living space green and vibrant, no matter what! Avoid the harmful and allergy-causing pollen and dander that are typical found in natural grass. </p>
                    <p className={"p-2 lg:p-4 leading-loose"}>From backyard to commercial projects artificial grass is a great choice to help decrease water bills and maintenance costs. We do artificial grass for backyards, playgrounds, driveways, dog parks, & more!</p>
                        <div className={"p-2 lg:p-4 py-8  "}>
                            <div className={"flex flex-row items-center p-2"}>

                                <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>No Maintenance</p>
                            </div>
                            <div className={"flex flex-row items-center p-2"}>

                                <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Always Green</p>
                            </div>
                            <div className={"flex flex-row items-center p-2"}>

                                <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>No More Lawn Moving</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={" px-4 py-16 bg-main-color-500"}>

                <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                    <h1 className={"text-center text-2xl text-white font-bold sm:text-3xl max-w-lg"}>{"Get Started on Your Artificial Grass Today"}</h1>
                    <Link to={"/contact#contact-form"}>
                        <button className={"bg-main-color-600 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Get Free Artificial Grass Quote</button>
                    </Link>
                </div>

            </div>
<div className={"flex flex-wrap-reverse flex-row justify-evenly items-center bg-main-color-700 text-white"}>


</div>



        </div>







































            <div id={"pavers-and-concrete"} className={"dark:bg-gray-900 dark:text-white transition duration-500 py-24"}>
                <div className={"flex flex-row-reverse flex-wrap-reverse justify-evenly lg:p-16 p-8 pb-0"}>
                    <div className={"flex flex-row flex-wrap lg:w-1/2 w-full justify-evenly"}>



                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pavers-concrete/new/o (2).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage  alt={"Example Work"} src={"../images/pavers-concrete/new/o (4).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage  alt={"Example Work"} src={"../images/pavers-concrete/new/o (1).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pavers-concrete/new/o.jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pavers-concrete/new/new.jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pavers-concrete/new/new3.jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pavers-concrete/new/new2.jpg"} />*/}
                        {/*</div>*/}
                        <ImagesSwiperPavers/>






                    </div>
                    <div className={"lg:w-1/2 w-full p-2 lg:p-4"}>
                        <div className={"max-w-lg mx-auto"}>
                            <h1 className={"text-5xl font-bold text-center pb-4"}>Pavers and Concrete</h1>
                            <h1 className={"text-3xl font-bold text-center pb-8 text-main-color-500"}>Paver & Concrete Experts</h1>

                            <p className={"p-2 lg:p-4 leading-loose"}>From driveways to patios and walkways we do a huge range of paving and concrete work that will make your outdoor living space come to life. With a huge variety and selection of flagstones, pavers, styles and colors we're able to build the projects of your dreams.</p>
                            <p className={"p-2 lg:p-4 leading-loose"}>Our concrete work is beautiful and affordable way to add patios, driveways, walls, planters, and walkways to your home. Our concrete is durable and won't crack under your cars weight.</p>
                            <div className={"p-2 lg:p-4 py-8  "}>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Craftsmanship</p>
                                </div>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Durable & Long Lasting</p>
                                </div>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Adds a Scenic Touch</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={" px-4 py-16 bg-main-color-500"}>

                    <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                        <h1 className={"text-center text-2xl text-white font-bold sm:text-3xl max-w-lg"}>{"Get Started on Your Pavers & Concrete Project Today"}</h1>
                        <Link to={"/contact#contact-form"}>
                            <button className={"bg-main-color-600 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Get Free Pavers & Concrete Quote</button>
                        </Link>
                    </div>

                </div>



            </div>







































            <div id={"back-yard-remodelling"} className={"dark:bg-gray-900 dark:text-white transition duration-500 py-24"}>
                <div className={"flex flex-row flex-wrap justify-evenly lg:p-16 p-8 pb-0"}>
                    <div className={"w-1/2"}>
                    <ImagesSwiperRemodel/>
                    </div>
                    {/*<div className={"flex flex-row flex-wrap lg:w-1/2 w-full justify-evenly"}>*/}



                    {/*    <div className={"p-2 lg:p-4 w-1/2"}>*/}
                    {/*        <StaticImage cl alt={"Example Work"} src={"../images/turf/new/o (3).jpg"} />*/}
                    {/*    </div>*/}
                    {/*    <div className={"p-2 lg:p-4 w-1/2"}>*/}
                    {/*        <StaticImage alt={"Example Work"} src={"../images/remodel/o (1).jpg"} />*/}
                    {/*    </div>*/}
                    {/*    <div className={"p-2 lg:p-4 w-1/2"}>*/}
                    {/*        <StaticImage alt={"Example Work"} src={"../images/remodel/o (2).jpg"} />*/}
                    {/*    </div>*/}
                    {/*    <div className={"p-2 lg:p-4 w-1/2"}>*/}
                    {/*        <StaticImage alt={"Example Work"} src={"../images/remodel/o.jpg"} />*/}
                    {/*    </div>*/}
                    {/*    <div className={"p-2 lg:p-4 w-1/2"}>*/}
                    {/*        <StaticImage alt={"Example Work"} src={"../images/remodel/new.jpg"} />*/}
                    {/*    </div>*/}
                    {/*    <div className={"p-2 lg:p-4 w-1/2"}>*/}
                    {/*        <StaticImage alt={"Example Work"} src={"../images/remodel/new2.jpg"} />*/}
                    {/*    </div>*/}






                    {/*</div>*/}
                    <div className={"lg:w-1/2 w-full p-2 lg:p-4"}>
                        <div className={"max-w-lg mx-auto"}>
                            <h1 className={"text-5xl font-bold text-center pb-4"}>Back Yard Remodeling</h1>
                            <h1 className={"text-3xl font-bold text-center pb-8 text-main-color-500"}>Transform Your Home With Our Back Yard Remodeling</h1>

                            <p className={"p-2 lg:p-4 leading-loose"}>Getting your back yard remodelled is an amazing way to improve your home and make your outdoor living space the focal point of your time outside. Our team of certified experts will help you achieve the yard of your dreams.</p>
                            <p className={"p-2 lg:p-4 leading-loose"}>Transform your yard into the entertaining and relaxing center of your home with everything you could dream of such as a fire pit, an outdoor kitchen, landscaping, water features & patios. </p>

                            <div className={"p-2 lg:p-4 py-8 "}>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Full Yard Makeover</p>
                                </div>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Top Notch Entertaining Spot</p>
                                </div>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Beautiful Landscaping</p>
                                </div>
                            </div></div>
                    </div>
                </div>

                <div className={" px-4 py-16 bg-main-color-500"}>

                    <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                        <h1 className={"text-center text-2xl text-white font-bold sm:text-3xl max-w-lg"}>{"Get Started on Your Backyard Remodel Today"}</h1>
                        <Link to={"/contact#contact-form"}>
                            <button className={"bg-main-color-600 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Get Free Backyard Remodelling Quote</button>
                        </Link>
                    </div>

                </div>



            </div>







































            <div id={"swimming-pools-and-spa"} className={"dark:bg-gray-900 dark:text-white transition duration-500 py-24 pb-0"}>
                <div className={"flex flex-row-reverse flex-wrap justify-evenly lg:p-16 p-8 pb-0"}>
                    <div className={"flex flex-row flex-wrap lg:w-1/2 w-full justify-evenly"}>

<ImageSwiperPools/>

                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pool/o (3).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pavers-concrete/new/o (2).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pool/o (5).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pool/o (4).jpg"} />*/}
                        {/*</div>*/}
                        {/*<div className={"p-2 lg:p-4 w-1/2"}>*/}
                        {/*    <StaticImage alt={"Example Work"} src={"../images/pool/o (9).jpg"} />*/}
                        {/*</div>*/}





                    </div>
                    <div className={"lg:w-1/2 w-full p-2 lg:p-4"}>
                        <div className={"max-w-lg mx-auto"}>
                            <h1 className={"text-5xl font-bold text-center pb-4"}>Swimming Pools and Spa</h1>
                            <h1 className={"text-3xl font-bold text-center pb-8 text-main-color-500"}>Trust The Pool Experts</h1>

                            <p className={"p-2 lg:p-4 leading-loose"}>What's better than your very own pool in your backyard. Whether you want a new pool and spa put in or just want to touch up and upgrade your current pool we have you covered, no matter how small or big! Give your entire family the pleasure of a pool so close to home.</p>
                            <p className={"p-2 lg:p-4 leading-loose"}>From water features to rocky decor, we will make your swimming pool and spa look modern and beautiful while providing all the functionality you need. We do water features, hot tubs, pool re-plasters, custom pools, and more!</p>
                            <div className={"p-2 lg:p-4 py-8  "}>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Custom Pool Builds</p>
                                </div>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Relax & Cool Down w/ Your Own Pool</p>
                                </div>
                                <div className={"flex flex-row items-center p-2"}>

                                    <svg className={"h-10 w-10 shrink-0 fill-main-color-600"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"/></svg>

                                    <p className={"text-xl pl-4 shrink-1 my-auto font-medium"}>Luxurious</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={" px-4 py-16 bg-main-color-500"}>
                    <div className={"flex justify-evenly items-center flex-wrap max-w-5xl mx-auto"}>
                        <h1 className={"text-center text-2xl text-white font-bold sm:text-3xl max-w-lg"}>{"Get Started on Your Swimming Pool & Spa Today"}</h1>
                        <Link to={"/contact#contact-form"}>
                            <button className={"bg-main-color-600 hover:bg-main-color-300 text-lg text-white font-bold py-4 px-8 rounded-full"}>Get Free Swimming Pool & Spa Quote</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}